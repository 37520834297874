<template>
  <b-modal
    id="modal-modify-ancillary-baggage-select-pax"
    header-bg-variant="light-info"
    :title="$t('reservation.addBaggage')"
    title-class="text-airline fw-700 font-medium-4 m-50"
    header-class="p-50"
    body-class="p-1"
    footer-class="p-50"
    no-close-on-backdrop
    no-enforce-focus
    centered
    size="lg"
    @show="showThisModal"
  >
    <template #modal-footer="{close}">
      <div class="w-100 d-flex-center">
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-secondary mr-1"
          pill
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="info"
          class="px-2 d-flex-center"
          pill
          :disabled="loadingNextButton"
          @click="openModalBaggageSecondHandle"
        >
          <div class="d-flex-center ml-25">
            <BSpinner
              v-if="loadingNextButton"
              small
              class="mx-2"
            />
            <span v-else>Tiếp tục</span>
          </div>
        </b-button>
      </div>
    </template>

    <div>
      <p class="fw-700 text-medium-1">
        Chọn hành khách để mua thêm gói hành lý cho từng hành trình:
      </p>

      <!-- NOTE: VN1A bỏ qua các hành trình có điểm dừng  https://discord.com/channels/1054696448110903327/1315896234463985694/1318860064261537793 -->
      <div v-if="[''].includes(bookingSource) && getBookingData.itineraries.some(trip => trip?.length > 1) && !isDevelopment">
        <b-alert
          show
          variant="warning"
        >
          <div class="alert-body text-center text-warning">
            <feather-icon
              class="mr-25"
              icon="AlertCircleIcon"
              size="25"
            />
            <span class="fw-700">
              Nếu có nhu cầu mua dịch vụ bổ sung cho chuyến bay có điểm dừng, vui lòng liên hệ booker hỗ trợ!
            </span>
          </div>
        </b-alert>
      </div>

      <BCard
        v-for="({trip, arrPax, addedAncillaryTrip, flights}, index) of dataList"
        :key="index"
        header-bg-variant="light-warning"
        header-class="fw-700 p-50 px-lg-1 mb-50"
        body-class="px-50 px-sm-1"
        class="border-warning"
      >
        <template #header>
          <div class="mr-25">
            <span class="pr-25 text-body">
              Hành trình: {{ ['TR'].includes(bookingSource) ? flights : `${trip[0].departure.iataCode}-${trip[trip.length - 1].arrival.iataCode}` }}
            </span>
            <span class="text-airline">
              ( {{ `${convertISODateTime(trip[0].departure.at, trip[0].departure.timeZone).dayAndMonth}` }} )
            </span>
            <span v-if="trip[0].status === 'CKIN'">{{ trip[0].status }}</span>
          </div>
          <div class="my-25 mr-sm-2">
            <!-- NOTE: Chỉ checkAll những pax chưa có bất kỳ gói hành trình nào trên hành trình -->
            <b-form-checkbox
              :id="`trip-select-${index + 1}`"
              class="text-airline fw-700 font-medium-1"
              :checked="isCheckedAll(arrPax, addedAncillaryTrip)"
              :disabled="disableSelectedAll(arrPax, addedAncillaryTrip) || trip[0].status === 'CKIN'"
              @change="toggleSelectAll(arrPax,addedAncillaryTrip)"
            >
              {{ isCheckedAll(arrPax, addedAncillaryTrip) ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
            </b-form-checkbox>
          </div>
        </template>

        <div>
          <!-- status trong segment là CKIN thì ko cho mua hành lý, seat (https://discord.com/channels/1054696448110903327/1299932651280662528/1300367492027518996) -->
          <BAlert
            v-if="trip[0].status === 'CKIN'"
            show
            variant="danger"
            class="text-danger px-2 py-50 fw-700 my-50"
          >
            Vé đã check-in không thể thêm chỗ ngồi
          </BAlert>
          <div
            v-for="(pax, indexPax) of dataPaxList"
            :key="indexPax"
            class="d-flex my-50 my-lg-75"
          >
            <b-form-checkbox
              :id="`pax-select-${index + 1}_${indexPax + 1}`"
              :checked="isCheckItem(arrPax, pax)"
              :disabled="disableSelectItem(arrPax, pax, addedAncillaryTrip)
                || (['TH'].includes(bookingSource) && addedAncillaryTrip.some(i => i.paxId === pax.paxId))
                || trip[0].status === 'CKIN'
              "
              @change="handleChooseItem(arrPax, pax)"
            >
              <span class="fw-700 pl-md-1">{{ pax.lastName }} {{ pax.firstName }}</span>
              <BBadge
                v-if="addedAncillaryTrip.some(i => i.paxId === pax.paxId)"
                variant="warning"
                class="mx-25 px-75 rounded-lg"
              >
                Đã có gói
              </BBadge>
            </b-form-checkbox>
          </div>
        </div>
      </BCard>

      <!-- https://discord.com/channels/1054696448110903327/1317046530032668744/1317146360536895571 -->
      <BAlert
        v-if="canBuyBaggageAddOnSourceTH && getBookingData.source === 'TH'"
        show
        variant="danger"
        class="px-2 py-1 fw-700"
      >
        <div>Lưu ý: Giá hành lý của hãng Scoot có thể thay đổi sau khi mua.</div>
        <div>Vui lòng thông báo đến khách hàng trước khi xác nhận thanh toán.</div>
      </BAlert>
    </div>

    <ModalBaggageSecondHandle :data-list-to-second="dataListToSecond" />
  </b-modal>
</template>

<script>
import {
  BCard,
  BButton,
  BModal,
  BBadge,
  BFormCheckbox,
  BSpinner,
  BAlert,
} from 'bootstrap-vue'
import {
  computed, ref,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import { groupBy } from 'lodash-es'

import env from '@/libs/env'

import { getShortNameTripByMultiSegments } from '@core/utils/utils'
import { convertISODateTime } from '@core/utils/filter'

import useReservation from '@reservation/useReservationHandle'
import useAncillaryServicesHandle from '@reservation/reservation-modify/components/detail/ancillary-services/useAncillaryServicesHandle'

import useToast from '@useToast'

export default {
  components: {
    BButton,
    BModal,
    BBadge,
    BCard,
    BFormCheckbox,
    BSpinner,
    BAlert,
    ModalBaggageSecondHandle: () => import('./modal-second-select-bagg.vue'),
  },
  setup() {
    const { toastWarning } = useToast()

    const {
      getBookingData,
    } = useReservation()

    const {
      fnAncillary,
      bookingSource,
      delay,
    } = useAncillaryServicesHandle()

    const dataList = ref()

    const dataPaxList = computed(() => getBookingData.value?.paxLists?.filter(p => !['INFANT'].includes(p.paxType)))
    const listSelectedAll = getBookingData.value.itineraries.map(() => false)
    const dataListToSecond = ref(null)
    const loadingNextButton = ref(false)

    // NOTE: TR HÀNH LÝ thì sẽ có option mua cho cả hành trình và option mua bỏ qua chặng ẩn (lấy đầu-cuối của chặng ko đổi máy bay)
    const getSegmentListWithHideSegment = (itineraryLists, ancillaryServiceLists, ssrType) => { // Dùng cho TR [|| TR chặng ẩn]
      const result = []

      const pushItem = segmentArr => {
        result.push({
          trip: segmentArr,
          indexTrip: Number(segmentArr[0].itineraryId),
          // indexSegment: segmentArr.map(it => it.segmentId).join(' | '),
          segmentIds: segmentArr.map(it => it.segmentId),
          flights: getShortNameTripByMultiSegments(segmentArr),
          addedAncillaryTrip: ancillaryServiceLists.filter(a => [ssrType].includes(a.serviceType) && !isEmpty(a.segmentIds) && a.segmentIds.some(aSegment => segmentArr.some(segment => segment.segmentId === aSegment))),
          arrPax: [],
          newAddItems: [],
        })
      }

      // const groupDataSegment = groupBy(itineraryLists.flat(), segment => segment.segmentId.slice(0, -1))
      const listGroupDataSegment = itineraryLists.map(trip => groupBy(trip, segment => segment.segmentId.slice(0, -1)))

      listGroupDataSegment.forEach(trip => {
        if (Object.values(trip).length > 1) {
          pushItem(Object.values(trip).flat())
        }

        Object.values(trip).forEach(segmentArr => {
        // if (segmentArr?.length > 1) {
        //   segmentArr.forEach(it => pushItem([it]))
        // }
          pushItem(segmentArr)
        })
      })

      return result
    }

    function getFlights(trip) { // JQ theo hành trình, ssr.flights với transit ('HAN-DAD | DAD-SGN')
      if (['JQ'].includes(bookingSource.value)) {
        return trip.map(s => `${s.departure.iataCode}-${s.arrival.iataCode}`).join(' | ')
      }
      return `${trip[0].departure.iataCode}-${trip[trip.length - 1].arrival.iataCode}`
    }

    // ============================== showThisModal
    function showThisModal() {
      const itineraryLists = getBookingData.value.itineraries
      const ancillaryServiceLists = getBookingData.value.ancillaryServices

      // NOTE: (['nếu disable transit thì thêm source vô đây'].includes(bookingSource.value) && !env.isDevelopment ? itineraryLists.filter(trip => trip?.length < 2) : itineraryLists)
      dataList.value = fnAncillary.value.BAGGAGE?.isSegment
        ? ['TR'].includes(bookingSource.value)
          ? getSegmentListWithHideSegment(itineraryLists, ancillaryServiceLists, 'BAGGAGE')
          : itineraryLists.map((trip, indexTrip) => trip.map(segment => ({
            trip: [segment],
            indexTrip,
            flights: `${segment.departure.iataCode}-${segment.arrival.iataCode}`,
            addedAncillaryTrip: ancillaryServiceLists.filter(a => ['BAGGAGE'].includes(a.serviceType) && !isEmpty(a.segmentIds) && a.segmentIds.some(aSegment => [segment].some(s => s.segmentId === aSegment))),
            arrPax: [],
            newAddItems: [],
          }))).flat(1)
        : itineraryLists.map((trip, indexTrip) => ({
          trip,
          indexTrip,
          flights: getFlights(trip),
          addedAncillaryTrip: ancillaryServiceLists.filter(a => ['BAGGAGE'].includes(a.serviceType) && !isEmpty(a.segmentIds) && a.segmentIds.some(aSegment => trip.some(s => s.segmentId === aSegment))),
          arrPax: [],
          newAddItems: [],
        }))

      dataListToSecond.value = null
    }

    async function openModalBaggageSecondHandle() {
      const filterData = dataList.value.filter(item => !isEmpty(item.arrPax))
      if (isEmpty(filterData)) {
        toastWarning({
          title: 'Chưa chọn hành khách!',
        })
        return
      }

      dataListToSecond.value = filterData.map(i => {
        const addonsOfPaxSelectedInTrip = i.addedAncillaryTrip.filter(a => i.arrPax.some(p => p.paxId === a.paxId))
        return {
          ...i,
          addedAncillaryTrip: addonsOfPaxSelectedInTrip,
          arrPax: i.arrPax.map(p => ({ ...p, addonsOfPax: addonsOfPaxSelectedInTrip.filter(a => a.paxId === p.paxId) })),
        }
      })

      loadingNextButton.value = true

      try {
        await delay(300)
        this.$bvModal.show('modal-modify-add-baggage')
      } catch (error) {
        console.error('Error openModalBaggageSecond::', error)
      } finally {
        await delay(200)
        loadingNextButton.value = false
      }
    }

    // ============================== CheckItem
    function isCheckItem(arrPax, pax) {
      return arrPax.some(p => p.paxId === pax.paxId)
    }
    function disableSelectItem(arrPax, pax, addedAncillaryTrip) {
      if (isEmpty(addedAncillaryTrip)) return false
      if (isEmpty(arrPax)) return false
      // Không check length gói đã mua ở đây vì k biết chính xác tổng gói đc thêm của các hãng (nếu có update)
      // "Đã chọn pax có addons => disable pax còn lại"
      // "Đã chọn pax chưa có addons => disable những pax đã có addons"
      const paxHasAddonsInArr = arrPax.some(p => addedAncillaryTrip.some(i => i.paxId === p.paxId))
      const thisPaxHasAddons = addedAncillaryTrip.some(i => i.paxId === pax.paxId)
      const result = paxHasAddonsInArr ? (arrPax[0].paxId !== pax.paxId) : thisPaxHasAddons
      return result
    }
    function handleChooseItem(arrPax, pax) {
      const index = arrPax.findIndex(p => p.paxId === pax.paxId)
      // eslint-disable-next-line no-unused-expressions
      index >= 0 ? arrPax.splice(index, 1) : arrPax.push(pax)
    }

    // ==================== CheckAll
    function disableSelectedAll(arrPax, addedAncillaryTrip) {
      if (isEmpty(addedAncillaryTrip)) return false
      const isSelectPaxHasAddons = arrPax.some(p => addedAncillaryTrip.some(i => i.paxId === p.paxId))
      if (isSelectPaxHasAddons) return true
      return dataPaxList.value.every(pax => addedAncillaryTrip.some(i => i.paxId === pax.paxId))
    }
    function allPaxChecked(arrPax, addedAncillaryTrip) { // SP
      const listPax = dataPaxList.value.filter(p => !addedAncillaryTrip.some(i => i.paxId === p.paxId))
      const a = (arrPax.length === listPax.length)
      const b = arrPax.every(pax => listPax.some(p => p.paxId === pax.paxId))
      return { listPax, checkAll: a && b }
    }
    function isCheckedAll(arrPax, addedAncillaryTrip) {
      if (isEmpty(arrPax)) return false
      const { checkAll } = allPaxChecked(arrPax, addedAncillaryTrip)
      return checkAll
    }
    function toggleSelectAll(arrPax, addedAncillaryTrip) {
      const { listPax, checkAll } = allPaxChecked(arrPax, addedAncillaryTrip)
      if (checkAll) {
        arrPax.splice(0, arrPax.length)
      } else {
        const result = listPax.filter(pax => !arrPax.some(p => p.paxId === pax.paxId))
        arrPax.push(...result)
      }
    }

    return {
      dataList,
      dataPaxList,
      listSelectedAll,
      isEmpty,
      dataListToSecond,
      loadingNextButton,
      fnAncillary,
      bookingSource,
      getBookingData,

      toggleSelectAll,
      disableSelectedAll,
      isCheckedAll,

      isCheckItem,
      handleChooseItem,
      disableSelectItem,

      convertISODateTime,

      showThisModal,
      openModalBaggageSecondHandle,
      canBuyBaggageAddOnSourceTH: env?.canBuyBaggageAddOnSourceTH,
      isDevelopment: env.isDevelopment,
    }
  },
}
</script>
